import React from "react";
import cialfoLogo from "../../assets/cialfo-logo.png";
import logo from "../../assets/logo.png";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getTabs, isTabSelected } from "./helper";
import Tab from "./tab";
import SubTabs from "./sub-tabs";

const SideBar = props => {
  const { t, visible, district, metabaseDashboards } = props;

  const location = useLocation();
  const path = location.pathname;

  const tabs = getTabs({ t, metabaseDashboards, district });

  return (
    <div
      className={
        "flex flex-col shadow side-bar" +
        (visible ? " side-bar-expanded" : " side-bar-collapsed")
      }
    >
      <div className="side-bar-logo-container mt-6 w-172px">
        {visible ? (
          <img
            className="w-98px h-36px"
            src={cialfoLogo}
            alt="Logo"
          />
        ) : (
          <img className="w-30px h-36px" src={logo} alt="Logo" />
        )}
      </div>
      <div className="flex-1 flex flex-col mt-6">
        {tabs.map(tab => {
          const selectedTab = isTabSelected({ tab, path });

          return (
            <div key={tab.url}>
              <Tab tab={tab} selectedTab={selectedTab} />
              {selectedTab && tab.subTabs && (
                <SubTabs tab={tab} path={path} location={location} />
              )}
            </div>
          );
        })}
      </div>
      {district.logo_url ? (
        <div className="flex items-center justify-center">
          <img
            className="mb-4 w-16 h-auto"
            src={district.logo_url}
            alt="District Logo"
          />
        </div>
      ) : null}
    </div>
  );
};

export default withTranslation()(SideBar);
